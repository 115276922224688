import { HTMLAttributes, useContext, useEffect, useRef } from 'react';

import Image from 'next/image';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import { Button as AntButton, Dropdown, MenuProps } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import cn from 'classnames';

import logout from '@/api/auth/logout';
import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { AvoidRedirectList } from '@/constants/common';
import { FeedbackContext } from '@/context/feeadback.context';
import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import { CompanyType } from '@/types/common-entities';
import Button from '@/UI/Button';

import { en } from './en';
import LangSelect from './LangSelect';

import './Header.scss';

const Logo = (props: Partial<LinkProps & HTMLAttributes<HTMLAnchorElement>>) => (
	<Link {...props} href="/">
		<Image
			src={`/img/${props.locale === 'ru' ? 'logo' : 'logo-en'}.svg`}
			alt={props.locale === 'ru' ? 'Логотип FishPlace' : 'Fisplace logo'}
			width={136}
			height={28}
			quality={100}
			priority
			style={{ width: '13.6rem', height: '2.8rem' }}
		/>
	</Link>
);

export default function Header() {
	const router = useRouter();
	const { locale } = router;
	const { message } = useContext(FeedbackContext);
	const { user, userCompany } = useContext(UserContext);
	const t = useTranslate(en);
	const isMobile = useMobile();

	const burger = useRef<HTMLButtonElement>();
	const header = useRef<HTMLElement>();

	const isShownForUnregistredAndFC = !user || user.company_type === CompanyType.FishCompany;

	function toggleMenu() {
		burger.current.classList.toggle('active');
		header.current.classList.toggle('visible');
		document.body.classList.toggle('scroll-disabled');
	}

	function closeMenu() {
		burger.current.classList.remove('active');
		header.current.classList.remove('visible');
		document.body.classList.remove('scroll-disabled');
	}

	useEffect(() => {
		const nav = document.querySelector('nav')!;
		Array.from(nav.querySelectorAll('a')).forEach(function (a) {
			a.addEventListener('click', closeMenu);
		});
	}, []);

	const lkUrl = () => {
		if (user) {
			return user.company_type === CompanyType.Warehouse
				? '/storage-account'
				: '/personal-account';
		}
		if (!AvoidRedirectList.some((path) => router.pathname.includes(path))) {
			return `/login?${REDIRECT_TO_QUERY}=${router.asPath}`;
		} else if (router.query[REDIRECT_TO_QUERY]) {
			return `/login?${REDIRECT_TO_QUERY}=${router.query[REDIRECT_TO_QUERY]}`;
		}
		return '/login';
	};

	const getUserName = () => {
		if (user.company_manager_name) {
			return user.company_manager_name;
		}
		if (user.first_name || user.last_name) {
			return `${user.first_name} ${user.last_name}`;
		}
		return t('Личный кабинет');
	};

	const handleLogout = async () => {
		try {
			await logout();
			void router.push('/login', '/login', { shallow: true });
		} catch (error) {
			message.error(
				'Ошибка при попытки выйти из личного кабинета. Попробуйте обновить страницу.',
			);
		}
	};

	const LogoutButton = (props: ButtonProps) => {
		return (
			<AntButton onClick={handleLogout} type="primary" {...props}>
				{t('Выйти')}
			</AntButton>
		);
	};

	const DigitalOfficeButton = (props: ButtonProps) => {
		return (
			<AntButton type="primary" {...props}>
				<Link
					href={`${window.location.origin}/office/${user.digital_office}`}
					target="_blank"
					rel="noreferrer"
				>
					{t('Цифровой офис')}
				</Link>
			</AntButton>
		);
	};

	const DigitalOfficeAdminButton = (props: ButtonProps) => {
		return (
			<AntButton type="primary" {...props}>
				<Link
					href={`${window.location.origin}/landing-admin`}
					target="_blank"
					rel="noreferrer"
				>
					{t('Лендинги')}
				</Link>
			</AntButton>
		);
	};

	const linkToLk = () => (
		<Link href={lkUrl()} onClick={closeMenu} aria-label="Перейти в личный кабинет">
			<div className="header-user">
				<div className="flex flex-col items-center sm:items-start gap-1">
					<b className="header-user-name">{t('Личный кабинет')}</b>
					{typeof user.role === 'string' && (
						<span className="header-user-role">
							{userCompany?.title_short ?? userCompany?.title_short_en ?? ''}
						</span>
					)}
				</div>
			</div>
		</Link>
	);

	const getItemsForDropdown = () => {
		const dropdownItems: MenuProps['items'] = logoutItems;

		if (user.digital_office) {
			dropdownItems.push(...digitalOfficeItems);
		}

		if (user.role === 'admin') {
			dropdownItems.push(...digitalOfficeAdminItems);
		}

		return dropdownItems;
	};

	const logoutItems: MenuProps['items'] = [
		{
			key: 'logout',
			label: <LogoutButton style={{ width: '100%' }} />,
		},
	];

	const digitalOfficeItems: MenuProps['items'] = [
		{
			key: 'digitalOffice',
			label: <DigitalOfficeButton style={{ width: '100%' }} />,
		},
	];

	const digitalOfficeAdminItems: MenuProps['items'] = [
		{
			key: 'digitalOffice',
			label: <DigitalOfficeAdminButton style={{ width: '100%' }} />,
		},
	];

	return (
		<>
			<header
				id="app-header"
				ref={header}
				className="header relative pl-[3.25rem] pr-[3.25rem]"
			>
				<button ref={burger} className="burger-button" onClick={toggleMenu}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							fill="#0A2653"
							d="M20 7H4a1 1 0 0 1 0-2h16a1 1 0 1 1 0 2Zm0 6H4a1 1 0 0 1 0-2h16a1 1 0 1 1 0 2ZM4 19h16a1 1 0 1 0 0-2H4a1 1 0 1 0 0 2Z"
						/>
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill="none"
						viewBox="0 0 24 24"
					>
						<path
							fill="#0A2653"
							d="m13.41 12 6.3-6.29a1.004 1.004 0 1 0-1.42-1.42L12 10.59l-6.29-6.3a1.004 1.004 0 0 0-1.42 1.42l6.3 6.29-6.3 6.29a1 1 0 0 0 0 1.42.998.998 0 0 0 1.42 0l6.29-6.3 6.29 6.3a.998.998 0 0 0 1.42 0 .997.997 0 0 0 .219-1.095.998.998 0 0 0-.22-.325L13.41 12Z"
						/>
					</svg>
				</button>

				<Logo locale={locale} className="mobile-logo" />

				{isMobile && <LangSelect />}

				<div
					className={cn('header-inner', {
						'demo-mobile-header': isMobile,
					})}
				>
					<div className="main-part-header">
						<Logo locale={locale} className="logo" />
						<nav
							className={cn('navigation', {
								'mobile-navigation': isMobile,
							})}
						>
							<ul>
								{isMobile && (
									<li>
										<Link
											className="whitespace-nowrap"
											href="/"
											locale={locale}
										>
											{t('Главная')}
										</Link>
									</li>
								)}
								{isShownForUnregistredAndFC && (
									<li>
										<Link
											className="whitespace-nowrap"
											href="/depositor-company"
											locale={locale}
										>
											{t('Рыбной компании')}
										</Link>
									</li>
								)}
								<li>
									<Link
										className="whitespace-nowrap"
										href="/storage"
										locale={locale}
									>
										{t('Складу')}
									</Link>
								</li>
								{isShownForUnregistredAndFC && (
									<li>
										<Link
											className="whitespace-nowrap"
											href="/buy-fish"
											locale={locale}
										>
											{t('Купить / продать')}
										</Link>
									</li>
								)}
								<li>
									<Link className="whitespace-nowrap" href="/order-transport">
										{t('Заказать транспорт')}
									</Link>
								</li>
								<li>
									<Link className="whitespace-nowrap" href="/about-us">
										{t('О платформе')}
									</Link>
								</li>
							</ul>
						</nav>
					</div>

					<div className="flex flex-col sm:flex-row items-center gap-[2.25rem]">
						<a
							className="header-phone"
							href="tel:+79089998080"
							aria-label="Позвонить по номеру 8 908 999 80 80"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="2.4rem"
								height="2.4rem"
								fill="currentColor"
								viewBox="0 0 24 24"
							>
								<path d="M19.44 13c-.22 0-.45-.07-.67-.12a9.439 9.439 0 0 1-1.31-.39 2 2 0 0 0-2.48 1l-.22.45a12.18 12.18 0 0 1-2.66-2 12.177 12.177 0 0 1-2-2.66l.42-.28a2 2 0 0 0 1-2.48 10.32 10.32 0 0 1-.39-1.31c-.05-.22-.09-.45-.12-.68a3 3 0 0 0-3-2.49h-3a3 3 0 0 0-3 3.41 19 19 0 0 0 16.52 16.46h.38a3 3 0 0 0 2.741-1.778c.173-.388.26-.808.259-1.232v-3a3 3 0 0 0-2.47-2.9Zm.5 6a1 1 0 0 1-.723.962 1.05 1.05 0 0 1-.437.038A17 17 0 0 1 4.07 5.22a1.09 1.09 0 0 1 .25-.82 1 1 0 0 1 .75-.34h3a1 1 0 0 1 1 .79c.04.273.09.543.15.81.115.527.27 1.045.46 1.55l-1.4.65a1 1 0 0 0-.49 1.33 14.49 14.49 0 0 0 7 7 1 1 0 0 0 .76 0 1 1 0 0 0 .57-.52l.62-1.4a13.68 13.68 0 0 0 1.58.46c.267.06.537.11.81.15a1 1 0 0 1 .79 1l.02 3.12Z" />
							</svg>
							+7 908 999 80 80
						</a>
						{user ? (
							!isMobile ? (
								<Dropdown
									menu={{ items: getItemsForDropdown() }}
									placement="bottom"
									overlayStyle={{ zIndex: 20001 }}
								>
									{linkToLk()}
								</Dropdown>
							) : (
								<>
									{linkToLk()}
									<LogoutButton />
									{user.digital_office && <DigitalOfficeButton />}
								</>
							)
						) : (
							<Button href={lkUrl()} onClick={closeMenu}>
								{t('Войти')}
							</Button>
						)}
						{!isMobile && <LangSelect />}
					</div>
				</div>
			</header>
		</>
	);
}
